import React from 'react';
import {Button, Modal} from 'react-bootstrap';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import "./UploadModal.css"
import API from "../API";
import Table from "react-bootstrap/Table";
import Spinner from 'react-bootstrap/Spinner';

class UploadExcelModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      dropdownSelected: null,
      dropdownItems: [],
      isReady: false,
      file: null,
      inProgress: false,
      errors: false,
    };
  }

  handleClose = () => {
    this.setState({show: false});
  }

  handleShow = () => {
    this.setState({show: true});
  }


  handleSelect = (e) => {
    this.setState({dropdownSelected: e})
    this.setState({isReady: true})
  }

  generateDropdownItems() {
    if (!this.state.dropdownItems.length) {
      API.leadsCount().then((leadsCount) => {
        let items = []
        leadsCount.map((item) => {
          items.push(<Dropdown.Item eventKey={item.company}>{item.company}</Dropdown.Item>)
        })
        this.setState({dropdownItems: items})
      });
    }
    return this.state.dropdownItems;
  }

  getDropdowntitle() {
    if (this.state.dropdownSelected) {
      return this.state.dropdownSelected
    }
    return 'Выберите компанию'
  }

  handleFileChange = (e) => {
    let type = e.target.files[0].type;
    if (type == 'application/vnd.ms-excel' || type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.setState({file: e.target.files[0]});
      this.setState({isReady: true})
    } else {
      alert('Файл должен быть в формате .xls или .xlsx');
      this.setState({isReady: false})
    }
  }
  handleUpload = () => {
    this.setState({inProgress: true}, () => {
      API.sendLeadExcel(this.state.file, this.state.dropdownSelected).then((response) => {
        if (response.status === 200) {
          alert("Файл успешно загружен")
          this.setState({errors: false})
        } else if (response.status === 400) {
          response.json().then((data) => {
            this.setState({errors: data})
          })
        } else {
          alert("Неизвестная ошибка загрузки файла")
        }
        this.setState({inProgress: false})
      })
    })
  }

  renderErrors() {
    if (this.state.errors) {
      let headers = Object.keys(this.state.errors.detail.bad_rows[0])
      let rows = this.state.errors.detail.bad_rows
      return (
        <>
          <p className="text-danger">{this.state.errors.detail.msg}:</p>
          <div className="alert alert-danger" role="alert">
            <Table className="table-danger">
              <thead>
              <tr>
                {headers.map((colName) => {
                  return <th>{colName}</th>
                })}
              </tr>
              </thead>
              <tbody>
              {rows.map((row) => {
                return (
                  <tr>
                    {headers.map((colName) => {
                      return <td>{row[colName]}</td>
                    })}
                  </tr>
                )
              })}
              </tbody>
            </Table>
          </div>
        </>
      )
    }
  }

  render() {
    return (
      <>
        <Button variant="primary" onClick={this.handleShow}>
          📂
        </Button>

        <Modal show={this.state.show} onHide={this.handleClose} dialogClassName="black-model">
          <Modal.Header closeButton>
            <Modal.Title>Загрузка лидов</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <DropdownButton as={ButtonGroup} key="company" id={`dropdown-variants-company`} variant="company"
                            title={this.getDropdowntitle()} onSelect={this.handleSelect}>
              {this.generateDropdownItems()}
            </DropdownButton>
            <input type="file" onChange={this.handleFileChange}/>
            {this.renderErrors()}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Закрыть
            </Button>
            <Button variant="primary" onClick={this.handleUpload}
                    disabled={!this.state.dropdownSelected || !this.state.file}>
              Загрузить
            </Button>
            <Spinner animation="border" role="status" variant="primary"
                     className={this.state.inProgress ? "" : "visually-hidden"}/>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default UploadExcelModal;
