import React from 'react'
import API from '../API'
import Table from "react-bootstrap/Table";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from "react-bootstrap";
import './LeadsTable.css'

class LeadsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leads: [],
      headers: [],
      nextUrl: null,
      previewsUrl: null,
      count: 0,
      offset: 0,
      pages: 0,
      currentPage: 1,
      filtersKeys: [],
      filterBy: null,
    }
  }

  async componentDidMount() {
    this.updateLeadsList(this.state.currentUrl)
    setInterval(async () => {
      this.updateLeadsList(this.state.currentUrl)
    }, 5000);
    API.leadsCount().then((leadsCount) => {
      let filters = []
      leadsCount.map((item) => {
        filters.push(item.company)
      })
      this.setState({filtersKeys: filters})
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedDate !== prevProps.selectedDate) {
      this.updateLeadsList(this.state.currentUrl);
    }
  }

  addParamToURL(key, value, url) {
    let params = new URLSearchParams(url)
    if (!value){
      params.delete(key)
    } else {
      params.set(key, value)
    }
    return decodeURIComponent(params.toString())
  }

  updateLeadsList(url = 'leads/?limit=45&offset=0') {
    url = this.addParamToURL('date', this.props.selectedDate, url)
    url = this.addParamToURL('filter_by', this.state.filterBy, url)
    console.log(url)
    API.getLeadsList(url).then((data) => {
      let offset = url.split('&').filter((item) => item.includes('offset'))[0].split('=')[1]
      let limit = url.split('&').filter((item) => item.includes('limit'))[0].split('=')[1]
      let headers = []
      if (data.leads.length > 0) {
        headers = Object.keys(data.leads[0])
      }
      this.setState({
        currentPage: Math.ceil(offset / limit) + 1,
        pages: Math.ceil(data.count / limit),
        leads: data.leads,
        headers: headers,
        nextUrl: data.next,
        previousUrl: data.previous,
        currentUrl: url,
        count: data.count
      })
    });
  }

  handleNext = () => {
    if (this.state.nextUrl) {
      this.updateLeadsList(this.state.nextUrl);
    }
  }

  handlePrevious = () => {
    if (this.state.previousUrl) {
      this.updateLeadsList(this.state.previousUrl);
    }
  }
  handleFirst = () => {
    this.updateLeadsList(this.state.currentUrl.split('&').filter((item) => !item.includes('offset')).join('') + `&offset=0`)
  }
  handleLast = () => {
    let limit = this.state.currentUrl.split('&').filter((item) => item.includes('limit'))[0]
    let offset = this.state.count - parseInt(limit.split('=')[1])
    this.updateLeadsList(this.state.currentUrl.split('&').filter((item) => !item.includes('offset')).join('') + `&offset=${offset}`)
  }

  renderFilters = () => {
    return this.state.filtersKeys.map((filter) => {
      return <Button variant="dark" onClick={this.setFilter} value={filter}>{filter}</Button>
    })
  }

  setFilter = (event) => {
    this.setState({filterBy: event.target.value}, () => {
      this.updateLeadsList(this.state.currentUrl)
    })
  }
  resetFilter = () => {
    this.setState({filterBy: null}, () => {
      this.updateLeadsList(this.state.currentUrl)
    })
  }

  render() {
    return (
      <>
        <div className="d-flex justify-content-center align-items-center">
          <Button variant="dart" onClick={this.handleFirst}>⏮</Button>
          <Button variant="dark" onClick={this.handlePrevious}>⬅</Button>
          {this.state.currentPage}/{this.state.pages}
          <Button variant="dark" onClick={this.handleNext}>➡</Button>
          <Button variant="dart" onClick={this.handleLast}>⏭</Button>
          <div className="filters">
            {this.renderFilters()}
            <Button variant="secondary" onClick={this.resetFilter}>X</Button>
          </div>
        </div>
        <Table striped bordered hover variant="dark">
          <thead>
          <tr>
            {this.state.headers.map((key) => {
              return <th key={key}>{key}</th>
            })}
          </tr>
          </thead>
          <tbody>
          {this.state.leads && this.state.leads.map((lead, index) => {

            return (
              <tr key={index}>
                {this.state.headers.map((key) => {
                  if (key === 'datetime' || key === 'refusal_datetime') {
                    let value = new Date(lead[key]).toLocaleString()
                    return <td key={key}>{value}</td>
                  }
                  if (key === 'sent') {
                    return (lead[key]) ? <td key={key}>✅️</td> : <td key={key}>❌</td>
                  }
                  return <td key={key}>{lead[key]}</td>
                })}
              </tr>
            )
          })}
          </tbody>
        </Table>
        <div className="d-flex justify-content-center align-items-center">
          <Button variant="dart" onClick={this.handleFirst}>⏮</Button>
          <Button variant="dark" onClick={this.handlePrevious}>⬅</Button>
          {this.state.currentPage}/{this.state.pages}
          <Button variant="dark" onClick={this.handleNext}>➡</Button>
          <Button variant="dart" onClick={this.handleLast}>⏭</Button>
        </div>
      </>
    )
  }
}


export default LeadsTable
