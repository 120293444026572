import React from "react";
import './Interface.css';
import LeadsTable from '../LeadsTable/LeadsTable';
import Chart from "../Chart/Chart";
import SendingSwitcher from "../SendingSwitcher/SendingSwitcher";


class Interface extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: null,
    }
    this.changeDate = this.changeDate.bind(this)
  }

  changeDate(date) {
    this.setState({selectedDate: date});
  }

  render() {
    return (
      <>
        <div className={'container-middle'}>
          <Chart OnChangeDate={this.changeDate} selectedDate={this.state.selectedDate}/>
          <SendingSwitcher/>
        </div>
        <LeadsTable selectedDate={this.state.selectedDate}/>
      </>
    )
  }
}

export default Interface

