import React, {useState} from 'react';
import Auth from './Components/Auth/Auth';
import Interface from './Components/Interface/Interface';
import './App.css';

function App() {
  const [token, setToken] = useState(false);

  const handleTokenChange = async (token) => {
    setToken(await token);
  };

  return (
    <div>
      {token ? (
        <Interface token={token}/>
      ) : (
        <Auth onTokenChange={handleTokenChange}/>
      )}
    </div>
  );
}

export default App;

