import React, { Component } from 'react';
import logo from '../../logo.svg';
import './Auth.css';
import API from '../API'

class Auth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: ''
    };
  }

  componentDidMount() {
    const token = this.getToken();
    if (token) {
      this.setState({ token: token });
      this.props.onTokenChange(this.checkToken(token));
    }
  }

  getToken() {
    return localStorage.getItem('token');
  }

  handleChange = (event) => {
    const newValue = event.target.value;
    this.setState({ token: newValue });
    this.saveTokenToStore(newValue);

    // Уведомляем родительский компонент об изменении токена
    if (this.props.onTokenChange) {
      this.props.onTokenChange(this.checkToken(newValue));
    }
  };

  saveTokenToStore(token) {
    localStorage.setItem('token', token);
  }

  checkToken = async (token) => {
    return await API.auth(token)
  };

  render() {
    return (
      <div>
        <div className="container-middle">
          <img src={logo} className="App-logo" alt="logo"/>
          <div >
            <h2>Введите токен доступа</h2>
            <input
              type="text"
              value={this.state.token}
              onChange={this.handleChange}
              placeholder="Введите значение"
              className="token-input"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Auth;

