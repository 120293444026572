import {PieChart} from "react-minimal-pie-chart";
import Table from "react-bootstrap/Table";
import React from "react";
import Form from "react-bootstrap/Form";
import API from "../API";
import {Button, Modal} from "react-bootstrap";
import UploadExcelModal from "../UploadModal/UploadModal";

class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      leadsCount: [],
      byDate: null,
    }

  }

  componentDidMount() {
    this.updateLeadsCount()
    setInterval(async () => {
      this.updateLeadsCount()
    }, 5000);
  }

  updateLeadsCount() {
    API.leadsCount(this.state.byDate).then((leadsCount) => {
      this.setState({leadsCount});
    });
  }

  colorByName(name) {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash + 2);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      let value = (hash >> (i * 8)) & 0xFF;
      color += ('00' + value.toString(16)).substr(-2);
    }
    return color;
  }

  buildData() {
    let data = []

    this.state.leadsCount.map((item) => {
      data.push({
        title: item.company,
        value: item.count,
        color: this.colorByName(item.company),
      })
    })
    return data
  }

  pickDate(event) {
    let date = new Date(event.target.value).toISOString()
    this.setState({byDate: date}, () => {
      this.updateLeadsCount()
      this.buildData()
      this.props.OnChangeDate(date)
    })
  }

  resetDate() {
    this.setState({byDate: null}, () => {
      this.updateLeadsCount()
      this.buildData()
      this.props.OnChangeDate(null)
    })
  }

  render() {
    let data = this.buildData()
    return (
      <div>
        <h1>Лиды</h1>
        <div className="lead-chart">
          <PieChart
            label={({x, y, dx, dy, dataEntry}) => (
              <text
                x={x}
                y={y}
                dx={dx}
                dy={dy}
                dominant-baseline="central"
                text-anchor="middle"
                style={{
                  fill: '#fff', pointerEvents: 'none', fontSize: '3px'
                }}>
                <tspan x={x} y={y} dx={dx} dy={dy}>{dataEntry.title}</tspan>
                <tspan x={x} y={y + 5} dx={dx} dy={dy}>{`${Math.round(dataEntry.value)}%`}</tspan>
              </text>
            )}
            data={data}
          />
          <div className={'leads-values'}>
            <Table variant="dark">
              <thead>
              <tr>
                <th>Компания</th>
                <th>Сумма</th>
                <th>Кол-во</th>
              </tr>
              </thead>
              <tbody>
              {this.state.leadsCount.map((item) => {
                let amount = Number(item.amount)
                return (
                  <tr key={item.company} style={{color: this.colorByName(item.company)}}>
                    <td> {item.company} </td>
                    <td> {amount.toLocaleString()} </td>
                    <td> {item.count} </td>
                  </tr>
                )
              })
              }
              </tbody>
            </Table>
            <div className="date-picker">
              <Form.Control type={'date'} onChange={(e) => this.pickDate(e)}/>
              <Button onClick={(e) => this.resetDate()} variant="secondary">X</Button>
              <UploadExcelModal/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Chart;
