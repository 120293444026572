class API {
  constructor(token) {
    this.API_URL = process.env.REACT_APP_APIURL
    this.TOKEN = "" || token
  }

  buildHeaders(method, token, body) {
    return {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'token': token
      },
      body: body
    }
  }

  auth(token) {
    return new Promise((resolve, reject) => {
      fetch(this.API_URL + '/auth', this.buildHeaders('POST', token, ''))
        .then(response => {
          if (response.status === 200) {
            this.TOKEN = token
            resolve(response.json());
          } else {
            resolve(undefined);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  }

  leadsCount(byDate) {
    let query = ''
    if (byDate){
      query = `by_date=${byDate}`
    }
    return new Promise((resolve, reject) => {
      fetch(this.API_URL + `/leads/count?${query}`,
        this.buildHeaders('GET', this.TOKEN, undefined))
        .then(response => {
          if (response.status === 200) {
            resolve(response.json());
          } else {
            resolve(false);
          }
        });
    });
  }

  getLeadsList(url){
    return new Promise((resolve, reject) => {
      fetch(`${this.API_URL}/${url}`, this.buildHeaders('GET', this.TOKEN, undefined))
        .then(response => {
          if (response.status === 200) {
            resolve(response.json());
          } else {
            resolve(false);
          }
        });
    });
  }

  getCompanies() {
    let filters = []
    this.leadsCount().then((leadsCount) => {
      leadsCount.map((item) => {
        filters.push(item.company)
      })
    });
    return filters
  }

  sendLeadExcel(file, company){
    const formData = new FormData();
    formData.append('file', file);
    return new Promise((resolve, reject) => {
      fetch(this.API_URL + `/leads/excel?company=${company}`,
        {
          method: 'POST',
          headers: {
            'Accept': 'accept: application/json',
            'token': this.TOKEN
          },
          body: formData
        })
        .then(response => {
          if (response.status === 200) {
            resolve(response);
          } else {
            resolve(response);
          }
        });
    });
  }

getSendingStatus() {
  return new Promise((resolve, reject) => {
    fetch(`${this.API_URL}/sending`, this.buildHeaders('GET', this.TOKEN, undefined))
      .then(response => {
        if (response.status === 200) {
          response.json().then(data => resolve(data.status));
        } else {
          resolve(false);
        }
      })
      .catch(() => resolve(false));
  });
}

  setSendingStatus(status=false){
    return new Promise((resolve, reject) => {
      fetch(`${this.API_URL}/sending?enable=${status}`, this.buildHeaders('POST', this.TOKEN, undefined))
        .then(response => {
          if (response.status === 200) {
            resolve(response.json().then(data => data.status));
          } else {
            resolve(false);
          }
        });
    });
  }
}

export default new API()
