import "./styles.css"
import API from "../API"
import {useEffect, useState} from "react";

const SendingSwitcher = () => {
  let defaultStatus = API.getSendingStatus()
  console.log("defaultStatus", defaultStatus)
  const [status, setStatus] = useState(defaultStatus)

  useEffect(() => {
    API.getSendingStatus().then(defaultStatus => {
      setStatus(defaultStatus);
      console.log("defaultStatus", defaultStatus);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      API.getSendingStatus().then(defaultStatus => {
        setStatus(defaultStatus);
        console.log("defaultStatus", defaultStatus);
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const changeStatus = () => {
    API.setSendingStatus(!status).then(r =>{
      setStatus(r.status)
      console.log("status", r.status)
    })
    return status
  }

  return (
    <div className={"flex fs-15"}>
      <p>Отправка лидов <br/>в rafinad.io</p>
      <label className="switch">
        <input type="checkbox" checked={status} onChange={changeStatus}/>
        <span className="slider round"></span>
      </label>
    </div>
  )
}

export default SendingSwitcher
